import { NodeItem } from './DataObject.NodeItem.ts';

import type { DataGridControl } from 'o365-datagrid';
import { logger } from 'o365-utils';


/** Row drag interface for node data */
export default class DataGridRowDrag {
    static get TransferType() { return 'o365-nt/data-grid-row-drag'; }

    private _dataGridControl: DataGridControl;

    get step() { return this.rowDrag.step; }
    get direction() { return this.rowDrag.direction }
    get isNodeDrag() { return true; }
    get rowDrag() { return this._dataGridControl.rowDrag; }

    get orderField() { return this.rowDrag.orderField; }
    get numberOrderMode() { return this.rowDrag._numberOrderMode; }
    get allowDragOver() { return this.rowDrag.allowDragOver; }

    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
        this._dataGridControl.dataObject?.nodeData.events.on('ConfigurationAdded', () => {
            this.validateConfigurationsForDragging();
        });
        this._dataGridControl.dataObject?.nodeData.events.on('ConfigurationRemoved', () => {
            this.validateConfigurationsForDragging();
        });
    }

    handleDrop(pRow: NodeItem, pAboveRow: NodeItem | undefined, pBelowRow: NodeItem | undefined, pDisplayIndex: number, pBottomEdge: boolean, pDragState: string) {
        // Remove dragged row from its parent scope
        const currentParent = pRow.getParent ? pRow.getParent() : undefined;
        let indexInCurrentParent = -1;
        if (currentParent) {
            indexInCurrentParent = currentParent.details.findIndex(x => x.key === pRow.key);
        } else {
            indexInCurrentParent = this._dataGridControl.dataObject!.nodeData.root.findIndex(x => x.key === pRow.key);
        }
        if (indexInCurrentParent !== -1) {
            if (currentParent) {
                currentParent.details.splice(indexInCurrentParent, 1);
            } else {
                this._dataGridControl.dataObject!.nodeData.root.splice(indexInCurrentParent, 1);
            }
        }

        this.updateSortOrder(pRow, pAboveRow, pBelowRow, pDisplayIndex, pBottomEdge);
        if (pDragState == 'over' && this.allowDragOver) {
            this.moveIntoItem(pRow, pAboveRow ?? pBelowRow);
        } else {
            this.updateParentBinding(pRow, pAboveRow, pBelowRow, pDisplayIndex, pBottomEdge);
        }
        this._dataGridControl.dataObject!.nodeData.update();
    }

    updateSortOrder(pRow: NodeItem, pAboveRow: NodeItem | undefined, pBelowRow: NodeItem | undefined, pDisplayIndex: number, pBottomEdge: boolean) {
        if (this.orderField == null || (pAboveRow == null && pBelowRow == null)) { NodeItem; }

        let newSortOrder = 0;
        if (pAboveRow == null) {
            const below = this._datetimeToNumber(pBelowRow![this.orderField]);
            if (this.direction === 'asc') {
                newSortOrder = below - this.step;
            } else {
                newSortOrder = below + this.step;
            }
        } else if (pBelowRow == null) {
            const above = this._datetimeToNumber(pAboveRow![this.orderField]);
            if (this.direction === 'asc') {
                newSortOrder = above + this.step;
            } else {
                newSortOrder = above - this.step;
            }
        } else if (pAboveRow!.parentKey == pBelowRow!.parentKey) {
            // row above and below are in the same scope
            const above = this._datetimeToNumber(pAboveRow![this.orderField]);
            const below = this._datetimeToNumber(pBelowRow![this.orderField]);
            if (above === below) {
                // TODO: Find new closest sort order and update all equal ones 
                // newSortOrder = (above + below) / 2;
                if (this.direction === 'asc') {
                    newSortOrder = below - this.step;
                } else {
                    newSortOrder = below + this.step;
                }
            } else {
                if (below > above){
                    newSortOrder = (above + below) / 2;
                } else { // if below value is smaller than above item should also be moved
                    if (this.direction === 'asc') {
                        newSortOrder = above + this.step;
                    } else {
                        newSortOrder = above - this.step;
                    }
                }
            }
        } else if (pAboveRow.key == pBelowRow.parentKey) {
            // row above is the parent of the below row
            const below = this._datetimeToNumber(pBelowRow![this.orderField]);
            if (this.direction === 'asc') {
                newSortOrder = below - this.step;
            } else {
                newSortOrder = below + this.step;
            }
        } else {
            // below row is on a diffrent scope, dont use it
            const above = this._datetimeToNumber(pAboveRow![this.orderField]);
            if (this.direction === 'asc') {
                newSortOrder = above + this.step;
            } else {
                newSortOrder = above - this.step;
            }
        }

        // else {
        //     const above = this._datetimeToNumber(pAboveRow[this.orderField]);
        //     const below = this._datetimeToNumber(pBelowRow[this.orderField]);
        //     if (above == below) {
        //         // TODO: sort orders match, need to find closet diffrent ones
        //         let aboveRowDisplayIndex = -1;
        //         let belowRowDisplayIndex = -1;
        //         if (pBottomEdge) {
        //             // display index is from above row
        //             aboveRowDisplayIndex = pDisplayIndex;
        //             belowRowDisplayIndex = pDisplayIndex + 1;
        //         } else {
        //             // display index is from below row 
        //             aboveRowDisplayIndex = pDisplayIndex - 1;
        //             belowRowDisplayIndex = pDisplayIndex;
        //         }

        //     }
        //     newSortOrder = (above + below) / 2
        // }
        pRow![this.orderField] = this.numberOrderMode ? newSortOrder : new Date(newSortOrder);
    }

    moveIntoItem(pRow: NodeItem, pParent: NodeItem) {
        if (pRow == null || pParent == null) { return; }
        const scope = pParent.details;
        scope.splice(pParent?.details.length, 0, pRow);

        pRow.getParent = () => pParent;
        const config = pRow.getConfiguration();
        config.updateNodeParent(pRow, pParent, pParent.parentId);
    }

    updateParentBinding(pRow: NodeItem, pAboveRow?: NodeItem, pBelowRow?: NodeItem) {
        if (pAboveRow == null && pBelowRow == null) { return; }

        let parent: NodeItem | undefined = undefined;
        let parentId: any = null;
        let searchForAbove = true;
        if (pAboveRow == null) {
            searchForAbove = false;
        } else if (pBelowRow == null) {
            parent = pAboveRow.getParent();
            parentId = pAboveRow.parentId;
        }
        else if (pAboveRow.parentKey == pBelowRow.parentKey) {
            // row above and below are in the same scope
            parent = (pAboveRow as any as NodeItem)?.getParent();
            parentId = pAboveRow.parentId;
        } else if (pAboveRow.key == pBelowRow.parentKey) {
            // row above is the parent of the below row
            searchForAbove = false;
            parent = pBelowRow?.getParent();
            parentId = pBelowRow?.parentId;
        } else {
            // below row is on a diffrent scope, dont use it
            parent = pAboveRow?.getParent();
            parentId = pAboveRow?.parentId;
        }

        const scope = parent?.details ?? this._dataGridControl.dataObject!.nodeData.root;
        if (searchForAbove) {
            const indexToInsert = scope.findIndex(x => x.key === pAboveRow!.key);
            scope.splice(indexToInsert + 1, 0, pRow);
        } else {
            const indexToInsert = scope.findIndex(x => x.key === pBelowRow!.key);
            scope.splice(indexToInsert, 0, pRow);
        }

        pRow.getParent = () => parent;
        const config = pRow.getConfiguration();
        config.updateNodeParent(pRow, parent, parentId);
    }

    sortRoot(pSearchPredicate: (a: any, b: any) => number, pRecursive = false) {
        const root = this._dataGridControl.dataObject!.nodeData.root;
        if (!root.length) { return; }
        const rootItem = {
            [this.orderField]: root[0][this.orderField],
            details: root,
        };

        this.sortItemDetails(rootItem, pSearchPredicate, pRecursive);
    }

    sortItemDetails(pItem: NodeItem, pSearchPredicate: (a: any, b: any) => number, pRecursive = false) {
        if (pItem == null || !Array.isArray(pItem.details)) { return; }
        const sortType = this.numberOrderMode ? 'float' : 'date';
        if (sortType == 'float') {
            const minSortOrder = pItem[this.orderField];
            pItem.details.sort(pSearchPredicate);
            pItem.details.forEach((child, index) => {
                child[this.orderField] = minSortOrder + index + 1;
                if (pRecursive) {
                    this.sortItemDetails(child, pSearchPredicate, pRecursive);
                }
            });
        } else if (sortType == 'date') {
            const minSortOrderDate = new Date(pItem[this.orderField]);
            pItem.details.sort(pSearchPredicate)
            pItem.details.forEach((child, index) => {
                child[this.orderField] = new Date(minSortOrderDate.getTime() + (index + 1) * 1000 * 60 * 60 * 24);
                if (pRecursive) {
                    this.sortItemDetails(child, pSearchPredicate, pRecursive);
                }
            })

        } else {
            logger.warn('No sorting field. This sort will not be stored in the database');
        }
        this._dataGridControl.dataObject!.nodeData.update();
    }

    private _datetimeToNumber(pDate: string | number | Date) {
        if (pDate instanceof Date) {
            return +pDate;
        } else if (typeof pDate === 'string') {
            return + new Date(pDate);
        } else {
            return pDate;
        }
    }

    validateConfigurationsForDragging() {
        const rowDrag = this._dataGridControl.rowDrag;
        rowDrag.validateSortFieldsForDragging();
        if (!rowDrag.canDrag) { return; }
        if (this._dataGridControl.dataObject == null) { return; }
        const nodeData = this._dataGridControl.dataObject.nodeData;
        rowDrag.canDrag = nodeData.configurations.length == 1 && nodeData.configurations[0].type == 'hierarchy';
    }

}

export type RowDragData = {
    index: number,
    key: string,
};